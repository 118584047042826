import { FILTER_TYPE_PRICE } from '@/components/Analytics/constants';
import { nonDefaultRefinementsFilter } from '@/components/Headings/utils';
import { fullyDecodeURI } from '@/components/SearchResultsFacets/utils';
import {
    REFINEMENT_KEY_DELIVERY,
    REFINEMENT_KEY_IN_STOCK,
    REFINE_QUERY_DELIMITER,
} from '@/constants/index';

export const getRatingsLabel = (rating: string): string => {
    return rating.replace('&', 'Stars &');
};

export function getSingleAttrValue(
    refinements?: string | string[] | null
): string {
    let value = '';

    if (!refinements) {
        return value;
    }

    const singleRefineParam = Array.isArray(refinements)
        ? refinements[0]
        : refinements;
    const refine = fullyDecodeURI(singleRefineParam)!;

    const attributes = refine
        .split(REFINE_QUERY_DELIMITER)
        .map(refinement => {
            let [key] = refinement.split(/-(.*)/s);
            return { filterType: key, refinement };
        })
        .filter(facet => nonDefaultRefinementsFilter)
        .filter(
            ({ filterType, refinement }) =>
                (filterType || refinement) &&
                filterType?.toLowerCase() !==
                    'item_location_pricing_saleprice' &&
                filterType?.toLowerCase() !== 'item_location_bopiw' &&
                refinement !== REFINEMENT_KEY_DELIVERY &&
                refinement !== REFINEMENT_KEY_IN_STOCK
        ); // Price is not a default refinement, but also should not be in it. Also catch REFINEMENT_KEY_DELIVERY

    if (attributes.length === 1 && attributes[0]) {
        const dashIndex = attributes[0].refinement.indexOf('-');
        value = attributes[0].refinement.slice(dashIndex + 1);
    }
    return value;
}
